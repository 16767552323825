import React from 'react';

const Roles = ({ plural }) => {

  const pluralArray = [{value: "Vagter", option: "Afspærring, vagter & mandskab"}, 
                      {value: "Animator", option: "Animatorer"},
                      {value: "Casting", option: "Casting/castere"},
                      {value: "Colorgrader", option: "Color graders"},
                      {value: "Computergrafik & -animation 3D", option: "Computergrafik & -animation 3D"},
                      {value: "Fotografassistent", option: "Fotografassistenter"},
                      {value: "Fotograf", option: "Fotografer"},
                      {value: "Grip", option: "Grip"},
                      {value: "Indspilningsleder", option: "Indspilningsledere"},
                      {value: "Instruktørassistent", option: "Instruktørassistenter"},
                      {value: "Instruktør", option: "Instruktører"},
                      {value: "Klippeassistent", option: "Klippeassistenter"},
                      {value: "Klipper", option: "Klippere"},
                      {value: "Komponist", option: "Komponister"},
                      {value: "Konsulent", option: "Konsulenter"},
                      {value: "Kostumier", option: "Kostumierer"},
                      {value: "Location Manager", option: "Location managers"},
                      {value: "Manuskriptforfatter", option: "Manuskriptforfattere"},
                      {value: "Postproducer", option: "Postproducere"},
                      {value: "Producer", option: "Producere"},
                      {value: "Produktionsassistent", option: "Produktionsassistenter"},
                      {value: "Produktionskoordinator", option: "Produktionskoordinatorer"},
                      {value: "Produktionsleder", option: "Produktionsledere"},
                      {value: "Regissør", option: "Regissører"},
                      {value: "Rekvisitør", option: "Rekvisitører"},
                      {value: "Scenograf", option: "Scenografer"},
                      {value: "Set & -regibygger", option: "Set & -regibyggere"},
                      {value: "Sminkør", option: "Sminkører"},
                      {value: "Speaker", option: "Speakere"},
                      {value: "Statistkoordinator", option: "Statistkoordinatorer"},
                      {value: "Steadicamoperatør", option: "Steadicamoperatører"},
                      {value: "Storyboardtegner", option: "Storyboardtegnere"},
                      {value: "Toneassistent", option: "Toneassistenter"},
                      {value: "Tonemester", option: "Tonemestre"}];

  const singularArray = [{value: "Vagter", option: "Afspærring, vagter & mandskab"}, 
                      {value: "Animator", option: "Animator"},
                      {value: "Casting", option: "Casting/caster"},
                      {value: "Colorgrader", option: "Color grader"},
                      {value: "Computergrafik & -animation 3D", option: "Computergrafik & -animation 3D"},
                      {value: "Fotografassistent", option: "Fotografassistent"},
                      {value: "Fotograf", option: "Fotograf"},
                      {value: "Grip", option: "Grip"},
                      {value: "Indspilningsleder", option: "Indspilningsleder"},
                      {value: "Instruktørassistent", option: "Instruktørassistent"},
                      {value: "Instruktør", option: "Instruktør"},
                      {value: "Klippeassistent", option: "Klippeassistent"},
                      {value: "Klipper", option: "Klipper"},
                      {value: "Komponist", option: "Komponist"},
                      {value: "Konsulent", option: "Konsulent"},
                      {value: "Kostumier", option: "Kostumier"},
                      {value: "Location Manager", option: "Location manager"},
                      {value: "Manuskriptforfatter", option: "Manuskriptforfatter"},
                      {value: "Postproducer", option: "Postproducer"},
                      {value: "Producer", option: "Producer"},
                      {value: "Produktionsassistent", option: "Produktionsassistent"},
                      {value: "Produktionskoordinator", option: "Produktionskoordinator"},
                      {value: "Produktionsleder", option: "Produktionsleder"},
                      {value: "Regissør", option: "Regissør"},
                      {value: "Rekvisitør", option: "Rekvisitør"},
                      {value: "Scenograf", option: "Scenograf"},
                      {value: "Set & -regibygger", option: "Set & -regibygger"},
                      {value: "Sminkør", option: "Sminkør"},
                      {value: "Speaker", option: "Speaker"},
                      {value: "Statistkoordinator", option: "Statistkoordinator"},
                      {value: "Steadicamoperatør", option: "Steadicamoperatør"},
                      {value: "Storyboardtegner", option: "Storyboardtegner"},
                      {value: "Toneassistent", option: "Toneassistent"},
                      {value: "Tonemester", option: "Tonemester"}];
  
    const rolesArray = plural ? pluralArray : singularArray;

    
    return rolesArray.map((role) => (
      <option key={role.value} value={role.value}>
        {role.option}
      </option>
    ));
};

export default Roles;