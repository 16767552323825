import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Container from "react-bootstrap/Container";
import ReCAPTCHA from "react-google-recaptcha";

function ProjectApply() {
  const [user, setUser] = React.useState({});
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [project, setProject] = React.useState({
    productionOwnerId: "",
    productionTitle: "",
    productionDescription: "",
    location: "",
    deadline: "",
    positions: [],
    categories: [],
    positionsCounter: "",
    paid: false,
    productionid: "",
  });
  const [formObject, setFormObject] = useState({
    senderNewId: "", // retrieve newId from user data
    senderFname: "", // retrieve first name from user data
    senderLname: "", // retrieve last name from user data
    senderSkills: [], // retrieve skills from user data
    senderLocation: "", // retrieve location from user data
    applyPosition: "",
    applyMessage: "",
    phone: "",
    email: "", // retrieve email from user data
    selectedRole: "", // Initialize selectedRole
  });
  const { id } = useParams();
  const [message, setMessage] = React.useState("Indlæser profil..");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = React.useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const recaptchaRef = React.createRef();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = recaptchaRef.current.getValue();

    if (!token) {
      setErrorMessage("Vær venlig at udfylde reCAPTCHA.");
      return;
    }
    recaptchaRef.current.reset();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/api/productions/${id}`,
        {
          selectedRoleId: formObject.selectedRole,
          fname: user.fname,
          lname: user.lname,
          senderNewId: user.newId,
          token: token,
          selectedRole: formObject.selectedRole,
          phone: formObject.phone,
          email: formObject.email,
          applyMessage: formObject.applyMessage,
        }
      );
      if (response.data.success) {
        setSubmitSuccess(true);
        setErrorMessage("");
      } else if (response.data.captchasuccess === false) {
        setErrorMessage(
          "Din reCAPTCHA verificering mislykkedes. Prøv at indlæs siden igen, og tryk på 'Jeg er ikke en robot' lige inden, du sender beskeden."
        );
      } else {
        console.log(formObject.selectedRole);
        setErrorMessage("Hov, der gik noget galt. Prøv igen.");
      }
    } catch (error) {
      console.log(formObject.selectedRole);
      console.error(error);
      setErrorMessage("Hov, der gik noget galt. Prøv igen.");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/checkuser', {
          withCredentials: true,
        });

        if (response.data.status) {
          setUser(response.data);
          setFormObject((prevFormObject) => ({
            ...prevFormObject,
            senderNewId: response.data.newId,
            senderFname: response.data.fname,
            senderLname: response.data.lname,
            senderSkills: response.data.skills,
            senderLocation: response.data.location,
            email: response.data.email,
          }));
          setLoading(false);
          setLoggedIn(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    async function fetchProject() {
      try {
        const response = await fetch(`/api/productions/${id}`);
        if (!response.ok) {
          const message = `Der er sket en teknisk fejl. Prøv igen senere.`;
          window.alert(message);
          return;
        } else {
          const productionHolder = await response.json();
          setProject(productionHolder);
          if (productionHolder.productionid) {
            setLoading(false);
          } else {
            setMessage("Det ser ud til, at du er gået forkert!");
          }
        }
      } catch (error) {
        console.error("Produktionen kunne ikke indlæses pga. en teknisk fejl", error);
      }
    }
    fetchProject();
  }, [id]);

  return (
    <div className="background-grey full-height pb-5">
      <Container>
        <div className="pt-5">
          {loggedIn === false ? (
            <>
              <Container className="div-height-hundred-vh">
                <div className="row underborder-black">
                  <div className="col-8">
                    <h3 className="mt-5">{message}</h3>
                  </div>
                </div>
              </Container>
            </>
          ) : (
            <>
              <Container className="div-height-hundred-vh">
                <div className="row underborder-black">
                  <div className="col-8">
                    <h3 className="mt-3">
                      Ansøg en rolle i {project.productionTitle} 
                    </h3>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-2">
                        <label htmlFor="selectedRole">Rolle</label>
                        <select
                          className="form-control"
                          id="selectedRole"
                          value={formObject.selectedRole}
                          onChange={(event) =>
                            setFormObject({
                              ...formObject,
                              selectedRole: event.target.value,
                            }) 
                            
                          }
                          required
                        >
                          <option value="" disabled>
                            Vælg rolle
                          </option>
                          {project.positions.map((position, index) => (
                            <option key={index} value={position.id}>
                              {position.skills}
                            </option>
                          ))}
                          <option value="Anden rolle">Anden rolle</option>
                        </select>
                      </div>

                      <div className="form-group mb-2">
                        <label htmlFor="senderPhone">Telefon</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          value={formObject.phone}
                          onChange={(event) =>
                            setFormObject({
                              ...formObject,
                              phone: event.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="applyMessage">Vedhæft en besked</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="applyMessage"
                          value={formObject.applyMessage}
                          onChange={(event) =>
                            setFormObject({
                              ...formObject,
                              applyMessage: event.target.value,
                            })
                          }
                          rows={6}
                          cols={60}
                          required
                        />
                      </div>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={recaptchaRef}
                      />
                      <p>{errorMessage}</p>
                      {submitSuccess ? (
                        <p className="mt-3">Din ansøgning er blevet sendt.</p>
                      ) : (
                        <button
                          type="submit"
                          className="button-search mt-3 mb-3"
                          disabled={loading}
                        >
                          Ansøg
                        </button>
                      )}
                    </form>
                  </div>
                  <div className="col pt-3">
                    <div className="row background-profile-box">
                      <div className="col-7 pt-2">
                        <h3 className="p-no-padding">{project.productionTitle}</h3>
                        <p>{project.location}</p>
                        <a href={`/produktioner/${id}`}>
                          <button className="button-read-more float-end me-2 mb-2">
                            Se beskrivelse af produktion
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ProjectApply;