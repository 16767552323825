import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import axios from "axios";
import LogoutButton from "../buttons/LogoutButton";

function NavBar() {
  const navbarRef = useRef(null);
  const logoRef = useRef(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const navbar = navbarRef.current;
    const logo = logoRef.current;

    const scrollFunction = () => {
      if (window.scrollY > 55) {
        navbar.style.borderBottom = "thin solid #e7e6e6";
      } else {
        // navbar.style.borderBottom = "none";
        navbar.style.borderBottom = "thin solid #e7e6e6";
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/checkuser", {
          withCredentials: true,
        });

        if (response.data.status) {
          console.log("User Data:", response.data); // Check what data is returned
          setUserData(response.data);
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    };

    const logout = async () => {
      try {
        await axios.post("/api/logout");

        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      } catch (error) {
        console.error("Logout failed:", error);
      }
    };

    fetchUserData();
    window.addEventListener("scroll", scrollFunction);
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);
  return (
    <Navbar expand="xl" ref={navbarRef} id="nav-bar-top" class="sticky-top">
      <Container id="nav-bar-top-width">
        <strong>
          {" "}
          <Navbar.Brand href="/" ref={logoRef}>
            <img
              src={require("../../images/icons8-film-50.png")}
              width="35"
              height="35"
              alt="Sun icon"
              className="me-2 mb-2"
            />
            Filmcrew.dk
          </Navbar.Brand>
        </strong>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <></>
          <Nav className="mx-auto">
            {/* <button class="signup-button-nav">Tilmelding for virksomhed og freelancer</button> */}
            <Nav.Link href="/produktioner" className="top-nav-link me-3">
              Produktioner
            </Nav.Link>
            {/* <Nav.Link href="/filmarbejdere" className="top-nav-link ">
              Filmarbejdere
            </Nav.Link></Nav> */}
            <Nav.Link href="/om" className="top-nav-link ">
              Om filmcrew.dk
            </Nav.Link>
          </Nav>

          <Nav className="ml-auto">
            {/* <button class="signup-button-nav">Tilmelding for virksomhed og freelancer</button> */}

            {/* <Nav.Link href="/tilbud" className="top-nav-link me-3 ">
              Indhent tilbud
            </Nav.Link> */}

            {userData ? (
              <>
                {/* <Nav.Link href="/login" className="top-nav-link me-1 hidden-visibility">
              Login

            </Nav.Link> */}

                <NavDropdown
                  title={
                    <span class="dropdown">
                      Din profil{" "}
                      {(userData.productionNotifications > 0 ||
                        userData.unreadMessages > 0) && (
                        <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      )}{" "}
                    </span>
                  }
                  className="top-nav-link dropdown"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item href="/beskeder">
                    Beskeder{" "}
                    {userData.unreadMessages > 0 && (
                      <span class="badge bg-danger">
                        {" "}
                        {userData.unreadMessages}{" "}
                      </span>
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/dine-produktioner">
                    Dine produktioner{" "}
                    {userData.productionNotifications > 0 && (
                      <span class="badge bg-danger">
                        {" "}
                        {userData.productionNotifications}{" "}
                      </span>
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/rediger-profil"
                    className="top-link-end"
                  >
                    Rediger profil
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={`/filmarbejdere/${userData.newId}`}
                    className="top-link-end"
                  >
                    Se din profil
                  </NavDropdown.Item>
                  <NavDropdown.Divider />

                  <LogoutButton />
                </NavDropdown>
              </>
            ) : (
              <>
                {/* <Nav.Link href="/login" className="top-nav-link me-1">
          Login
        </Nav.Link>
 
        <a href="/tilmeld" className="zero-padding-right">
        <button class="signup-button-nav ">Opret profil</button>
        </a>
        */}
                <Nav.Link href="/login" className="top-link-end me-1 ">
                  <span className="me-2">
                    Login{" "}
                    <img
                      src={require("../../images/icons8-lock-50.png")}
                      width="25"
                      height="25"
                      alt="Sun icon"
                      className="me-1 pb-1 mb-1"
                    />
                  </span>
                </Nav.Link>

                <button class="signup-button-nav ">Opret profil</button>
                {/* <Nav.Link href="/login" className="top-link-end me-1 zero-padding-right">
          Opret profil <img src={require('../../images/timeicon.png')} width="25" height="25" alt="Sun icon"/>
        </Nav.Link> */}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
