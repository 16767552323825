import Container from "react-bootstrap/Container";
import React from "react";
import { useParams } from "react-router-dom";

function Project() {
  const [project, setProject] = React.useState({
    productionOwnerId: "",
    productionTitle: "",
    productionDescription: "",
    location: "",
    deadline: "",
    positions: [],
    categories: [],
    positionsCounter: "",
    paid: false,
    productionid: ""
  });
  const [loading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [message, setMessage] = React.useState("Indlæser profil..");

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`/api/productions/${id}`);
        if (!response.ok) {
          const message = `Der er sket en teknisk fejl. Prøv igen senere.`;
          window.alert(message);
          return;
        } else {
          const productionHolder = await response.json();
          setProject(productionHolder);
          if (productionHolder.productionid) {
            setLoading(false);
          } else {
            setMessage("Det ser ud til, at du er gået forkert!");
          }
        }
      } catch (error) {
        console.error("Profilen kunne ikke indlæses pga. en teknisk fejl", error);
      }
    }

    fetchData();
  }, [id]);

  return (
    <div className="background-grey pb-5">
      {loading ? (
        <Container className="div-height-90-vh">
          <div className="row underborder-black">
            <div className="col-8">
              <h3 className="mt-5">{message}</h3>
            </div>
          </div>
        </Container>
      ) : (
        <Container className="div-height-90-vh">
          <div className="row">
            <div className="col-8">
              <h1 className="mt-5">{project.productionTitle}</h1>
              <p className="lead black-text">{project.productionDescription}</p>
              <p>{project.location}</p>
              <p>Deadline: {project.deadline}</p>
              <p className="p-no-padding">
                  {project.categories.map((category, index) => (
                    <span key={index} className="badge blue-ish-background me-1">{category}</span>
                  ))}
                  {project.paid && <span className="badge green-background">En eller flere betalte roller</span>}
                </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 mt-5">
              <div className="profile-feed-card mb-3">
                <div className="relative-class-holder">
                  <img
                    className="profile-imagine-profile"
                    src={project.avatar_img || "placeholder.jpg"}
                    alt="Production"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                  <div className="lowBoxShadowNoBackground-hundredWidth-noHeight background-white">
                    <div className="p-3">
                      <h5 className="mb-3">Åben for ansøgninger</h5>
                      <p className="mt-2 text-low-padding-bot">
                        Du kan specificere dine ansøgninger i næste trin.
                      </p>
                    </div>
                    <center></center>{" "}
                    <a href={`/productions/${project.productionid}/apply`}>
                      <button className="ms-2 mb-3 button-search mt-0">
                        Ansøg nu
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>
            
            <div className="col-12 col-md-8 mt-5">
              <div className="row mb-3">
                <div className="col">
                  <div className="mb-1">
                    <h3 className="">Om produktionen</h3>
                  </div>
                  <p style={{ whiteSpace: "pre-line" }}>{project.productionDescription}</p>
                </div>
              </div>

              <div className="row mb-3">
                <h3>Rolleopslag</h3>
              </div>
              <div className="underborder-black-small mb-3">
                <div className="row">
                  <div className="col-4">
                    <p className=" text-no-padding-bot">Rolle</p>
                  </div>
                  <div className="col-2">
                    <p className=" text-no-padding-bot">Kategori</p>
                  </div>
                  <div className="col-2">
                    <p className=" text-no-padding-bot">Antal</p>
                  </div>
                  <div className="col-4">
                    <p className=" text-no-padding-bot">Ansøgning</p>
                  </div>
                </div>
              </div>
              {project.positions.length > 0 ? (
                project.positions.map((position, index) => (
                  <div className="row" key={index}>
                    <div className="col-4">
                      <p>{position.title}</p>
                    </div>
                    <div className="col-2">
                      <p>{position.category}</p>
                    </div>
                    <div className="col-2">
                      <p>{position.count}</p>
                    </div>
                    <div className="col-4">
                      <a href={`/productions/${project.productionid}/kontakt`}>
                        Ansøg her
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <p>Ingen opslag for denne produktion.</p>
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}

export default Project;
