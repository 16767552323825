import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProjectsCreatedOverview = () => {
  const [user, setUser] = useState(null);
  const [productions, setProductions] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const showMore = (posid) => {
    // Implement the showMore function logic here
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/api/checkuser`);
        console.log("Check User Response:", response.data);
        if (!response.data.status) {
          const message = `Der er sket en teknisk fejl. Prøv igen senere.`;
          setMessage(message);
          setError(true);
          setLoading(false);
          return;
        } else if (response.data.status) {
          const userHolder = response.data;
          setUser(userHolder);

          if (userHolder.newId) {
            try {
              const productionsResponse = await axios.get(`/api/profilesproductions/${userHolder.id}`);
              setProductions(productionsResponse.data.results);
            } catch (error) {
              console.error('Error fetching productions:', error);
              setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
              setError(true);
              setLoading(false);
            }
          } else if (!userHolder.newId) {
            setMessage("Det ser ud til, at du er gået forkert!");
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Profilen kunne ikke indlæses pga. en teknisk fejl", error);
        setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
        setError(true);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{message}</div>;
  }

  return (
    <div>
      <h1>Projects Created Overview</h1>
      {productions.map((production, index) => (
        <div key={index}>
          <h2>{production.title}</h2>
          <button className="button-message-read-more-decline mb-2" onClick={() => showMore(production.id)}>
            Læs mere
          </button>
        </div>
      ))}
    </div>
  );
};

export default ProjectsCreatedOverview;